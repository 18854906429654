<template>
  <div class="programDetailsBox">
    <el-row style="width: 100%">
      <el-col :span="24" class="programDetailsBackImg">
        <el-image :src="programBackImg" fit="cover" style="width: 100%; height: 100%;cursor: pointer;"></el-image>
      </el-col>
    </el-row>

    <!-- 详情 -->
    <div class="programDetails-1200">
      <div class="detailsTitle">{{programInfo.title}}</div>
      <div class="detailsTiem">发布时间：{{formatDate(programInfo.publishTime)}}</div>
      <div class="videoRq" v-if="playType == 1">
        <video class="videoBf" ref="myVideo" :src="programInfo.video" controls="controls">您的浏览器不支持视频播放</video>
      </div>
      <div class="videoRq" v-if="playType == 2" v-html="programInfo.video"></div>
      <div style="width: 100%;margin-bottom: 20px;">
        <el-image :src="programInfo.coverImg" fit="cover" style="width: 100%; cursor: pointer;"></el-image>
      </div>
      <div style="padding-bottom: 150px;" v-html="programInfo.content"></div>
    </div>





  </div>
  </div>
</template>

<script>
  import {
    request
  } from '@/utils/request';
  export default {
    components: {},
    data() {
      return {
        programBackImg: "http://djs.thinkerwork.com/20230824144117412_未标题-1@2x.png",

        //详情id
        id: 0,
        //详情数据
        programInfo: {
          content: "",
          coverImg: "",
          video: "https://djs.thinkerwork.com/%E8%A7%86%E9%A2%91/%E5%BF%83%E5%AE%A2%20%E9%A2%84%E8%A7%81%E7%8B%AC%E8%A7%92%E5%85%BD.mp4",
        },
        //播放类型
        playType: 0,
      };
    },
    created() {
      var id = this.$route.query.id;
      this.getInfo(id);
    },
    methods: {
      /* 往期详情 */
      getInfo(id) {
        request('https://xinke.thinkervc.cn/api/web/activityCms/getPreviousProgram?id=' + id, {}, {
          method: 'get'
        }).then(res => {
          if (res.code === 200) {
            var video = res.data.video;
            if (video.indexOf('http') !== -1) {
              this.playType = 1;
            } else {
              this.playType = 2;
            }
            this.programInfo = res.data;
          }
        });
      },
      /* 时间转成年月日 */
      formatDate(dateStr) {
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
    }
  };
</script>

<style lang="scss" scoped>
  .programDetailsBox {
    width: 100%;
    min-height: 100vh;
  }

  .programDetailsBackImg {
    width: 100%;
    height: 360px;
  }

  .programDetails-1200 {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 100px;
  }

  .detailsTitle {
    font-size: 26px;
    font-family: Source Han Sans CN, Source Han Sans CN-Medium;
    font-weight: 500;
    text-align: center;
    color: #000000;
    letter-spacing: 1.4px;
    margin-bottom: 15px;
  }

  .detailsTiem {
    font-size: 14px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    text-align: center;
    color: #666666;
    letter-spacing: 0.8px;
  }

  .videoRq {
    width: 100%;
    min-height: 520px;
    overflow: hidden;
    padding: 48px 150px;
    border-bottom: 1px solid #cfcfcf;
    margin-bottom: 30px;
  }

  ::v-deep .videoRq iframe {
    width: 100%;
    height: 520px;
  }

  .videoBf {
    width: 100%;
    height: 520px;
  }

  @media screen and (max-width: 1200px) {}
</style>
